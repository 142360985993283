import React from "react";
import styles from "./Two-column-text.module.css";

function TwoColumnText({ title, c1, c2, background, mode }) {
  return (
    <div className={styles.TwoColumnText}>
      <div
        className="container"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: `center`,
          backgroundSize: `cover`,
        }}
      >
        <h2 className={mode}>{title}</h2>

        <div className="item">
          <p className={mode}>{c1}</p>
          <p className={mode}>{c2}</p>
        </div>
      </div>
    </div>
  );
}

export default TwoColumnText;
