import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";
import background from "../../assets/images/Navbar.png";
import { useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();

  /*const [page, setPage] = useState(() => {
    const savedItem = localStorage.getItem("pageKey");
    const parsedItem = JSON.parse(savedItem);
    return parsedItem || "home";
  });

  useEffect(() => {
    localStorage.setItem(`pageKey`, JSON.stringify(page));
  }, [page]);*/

  return (
    <div className={styles.Navbar} id="navbar">
      <div className="container">
        <div className="item">
          <Link to="/">
            <button
              className="nav"
              style={
                location.pathname === "/"
                  ? {
                      fontWeight: `500`,
                      textDecoration: `underline solid #859c3e 5px`,
                      textUnderlineOffset: `10px`,
                    }
                  : {}
              }
            >
              Home
            </button>
          </Link>
        </div>
        <div className="item">
          <Link to="/gerichte">
            {" "}
            <button
              className="nav"
              style={
                location.pathname === "/gerichte"
                  ? {
                      fontWeight: `500`,
                      textDecoration: `underline solid #859c3e 5px`,
                      textUnderlineOffset: `10px`,
                    }
                  : {}
              }
            >
              Gerichte
            </button>
          </Link>
        </div>
        <div className="item">
          <Link to="/preise">
            <button
              className="nav"
              style={
                location.pathname === "/preise"
                  ? {
                      fontWeight: `500`,
                      textDecoration: `underline solid #859c3e 5px`,
                      textUnderlineOffset: `10px`,
                    }
                  : {}
              }
            >
              Service &#38; Preise
            </button>
          </Link>
        </div>
        <div className="item">
          <Link to="/galerie">
            <button
              className="nav"
              style={
                location.pathname === "/galerie"
                  ? {
                      fontWeight: `500`,
                      textDecoration: `underline solid #859c3e 5px`,
                      textUnderlineOffset: `10px`,
                    }
                  : {}
              }
            >
              Galerie
            </button>
          </Link>
        </div>
        <div className="item">
          <Link to="/kontakt">
            <button
              className="nav"
              style={
                location.pathname === "/kontakt"
                  ? {
                      fontWeight: `500`,
                      textDecoration: `underline solid #859c3e 5px`,
                      textUnderlineOffset: `10px`,
                    }
                  : {}
              }
            >
              Kontakt
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
