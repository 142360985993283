import React from "react";
import styles from "./Title.module.css";

function Title({ title, text }) {
  return (
    <div className={styles.Title}>
      <div className="container">
        <div className="item header">
          <h2>{title}</h2>
          {text}
        </div>
      </div>
    </div>
  );
}

export default Title;
