import React from "react";
import styles from "./Pictures.module.css";

function Pictures() {
  function generateRow(nStart) {
    let images = [];
    for (let n = nStart; n <= 12; n++) {
      images.push(
        <img
          className="pic"
          src={require(`../../assets/gallery/gallery-${n}.jpg`)}
          alt="Gallery Image"
        />
      );
      n = n + 2;
    }
    return images;
  }

  return (
    <div className={styles.Pictures}>
      <div className="container">
        <div className="item">{generateRow(1)}</div>
        <div className="item">{generateRow(2)}</div>
        <div className="item">{generateRow(3)}</div>
      </div>
    </div>
  );
}

export default Pictures;
