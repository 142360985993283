import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import icon from "../../assets/icons/Icon-Chilli.png";

function Footer() {
  return (
    <div className={styles.Footer} id="footer">
      <ul className="container">
        <li className="item contact">
          <p>
            Pia Snay<br></br>
            sarapeey(at)gmx.de<br></br>
            +49 175 1057359<br></br>
            64560 Riedstadt / Erfelden<br></br>
            Hildegard-von-Bingen-Str. 28
          </p>
        </li>
        <li className="item title">
          <h3>Follow your Taste</h3>
        </li>
        <li className="item extras">
          <Link to="/impressum">
            <button className="light">Impressum</button>
          </Link>
          <Link to="/datenschutz">
            <button className="light">Datenschutz</button>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
