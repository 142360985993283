import React, { useRef } from "react";
import styles from "./ContactForm.module.css";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";

function ContactForm() {
  const form = useRef();
  const [btndisabled, setBtndisabled] = useState(true);
  const [isMailSent, setIsMailSent] = useState(false);

  function enableBtn(value) {
    console.log(btndisabled);
    setBtndisabled(false);
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    /*emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID, //Service-ID
        process.env.REACT_APP_TEMPLATE_ID, //Template-ID
        form.current,
        process.env.REACT_APP_PUBLIC_KEY //Public-Key
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );*/

    const formData = new FormData(e.target);
    formData.append("service_id", process.env.REACT_APP_SERVICE_ID);
    formData.append("template_id", process.env.REACT_APP_TEMPLATE_ID);
    formData.append("user_id", process.env.REACT_APP_PUBLIC_KEY);

    try {
      const response = await fetch(
        "https://api.emailjs.com/api/v1.0/email/send-form",
        {
          method: "POST",
          body: formData,
          headers: {
            // No need to specify 'Content-Type' or set 'processData' to false
          },
        }
      );

      if (response.ok) {
        setIsMailSent(true);
        alert(
          "Deine Anfrage wurde erfolgreich versendet. Pia wird sich so bald wie möglich bei dir melden!"
        );
        window.location.reload(false);
      } else {
        const errorData = await response.json();
        alert(
          "Es gab einen Fehler beim Versenden der Anfrage. Bitte versuche es später nochmal oder kontaktiere Pia Snay direkt per Whatsapp, Email oder Telefon."
        );
        console.log("Oops... " + JSON.stringify(errorData));
        window.location.reload(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert(
        "Es gab einen Fehler beim Versenden der Anfrage. Bitte versuche es später nochmal oder kontaktiere Pia Snay direkt per Whatsapp, Email oder Telefon."
      );
      window.location.reload(false);
    }

    //e.target.reset();
    /*alert(
      "Deine Anfrage wurde erfolgreich versendet. Pia wird sich so bald wie möglich bei dir melden!"
    );
    window.location.reload(false);*/
  };

  return (
    <div className={styles.ContactForm}>
      <div className="container">
        <h2>Kontaktformular</h2>

        <form ref={form} onSubmit={sendEmail}>
          <div className="formField">
            <label className="formLabel">
              Name <span>*</span>
            </label>
            <input className="formInput" type="text" name="name" required />
          </div>
          <div className="formField middle">
            <label className="formLabel">
              Email <span>*</span>
            </label>
            <input className="formInput" type="email" name="email" required />
          </div>
          <div className="formField middle">
            <label className="formLabel">Tel.</label>
            <input className="formInput" type="text" name="telefone" />
          </div>
          <div className="formField">
            <label className="formLabel">
              Message <span>*</span>
            </label>
            <textarea className="formInput message" name="message" required />
          </div>
          <p className="notice datenschutz">
            * Es gilt unsere{" "}
            <Link
              to="/datenschutz"
              style={{ textDecoration: "none", color: "#859c3e" }}
            >
              Datenschutzerklärung
            </Link>
          </p>
          <div className="recaptcha">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              onChange={enableBtn}
            />
          </div>

          <button
            disabled={btndisabled ? true : false}
            className={btndisabled ? "disabled" : "dark"}
            type="submit"
            value="Absenden"
          >
            Absenden
          </button>

          <p className="notice">
            <b>Hinweis:</b> Bitte die mit <span>*</span> gekennzeichneten Felder
            ausfüllen
          </p>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
