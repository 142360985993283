import React from "react";
import styles from "./Datenschutz.module.css";
import { useEffect } from "react";

function Datenschutz() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Datenschutz}>
      <div className="container">
        <h2>Datenschutz</h2>
        <h3>
          Datenschutzerklärung - Allgemeiner Hinweis und Pflichtinformationen
        </h3>
        <p className="headline">Benennung der verantwortlichen Stelle</p>
        <p className="content">
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:<br></br>
          <br></br> Thomas Snay<br></br>
          Hildegard-von-Bingen-Str. 28
          <br></br> 64560 Riedstadt<br></br>
          <br></br> Die verantwortliche Stelle entscheidet allein oder gemeinsam
          mit anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        </p>
        <p className="headline">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </p>
        <p className="content">
          Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
          Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
          Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
          formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
          Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>

        <p className="headline">
          Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
        </p>
        <p className="content">
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
          Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
          ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
          Sitz unseres Unternehmens befindet. Der folgende Link stellt eine
          Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
        </p>

        <p className="headline">Recht auf Datenübertragbarkeit</p>
        <p className="content">
          Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
          Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
          die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.{" "}
        </p>

        <p className="headline">
          {" "}
          Recht auf Auskunft, Berichtigung, Sperrung, Löschung
        </p>
        <p className="content">
          {" "}
          Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an
          uns wenden.
        </p>

        <p className="headline"> SSL- bzw. TLS-Verschlüsselung </p>
        <p className="content">
          Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
          Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
          Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
          über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
          erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
          Ihres Browsers und am Schloss-Symbol in der Browserzeile.{" "}
        </p>

        <p className="headline">Newsletter-Daten</p>
        <p className="content">
          Zum Versenden unseres Newsletters benötigen wir von Ihnen eine
          E-Mail-Adresse. Eine Verifizierung der angegebenen E-Mail-Adresse ist
          notwendig und der Empfang des Newsletters ist einzuwilligen.
          Ergänzende Daten werden nicht erhoben oder sind freiwillig. Die
          Verwendung der Daten erfolgt ausschließlich für den Versand des
          Newsletters. Die bei der Newsletteranmeldung gemachten Daten werden
          ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
          DSGVO) verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung
          ist jederzeit möglich. Für den Widerruf genügt eine formlose
          Mitteilung per E-Mail oder Sie melden sich über den "Austragen"-Link
          im Newsletter ab. Die Rechtmäßigkeit der bereits erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Zur
          Einrichtung des Abonnements eingegebene Daten werden im Falle der
          Abmeldung gelöscht. Sollten diese Daten für andere Zwecke und an
          anderer Stelle an uns übermittelt worden sein, verbleiben diese
          weiterhin bei uns.{" "}
        </p>
      </div>
    </div>
  );
}

export default Datenschutz;
{
  /* 
          
          
          <br></br> 
          <br></br> {" "}
          <br></br> 
          <br></br> <br></br> <br></br><br></br><br></br><br></br> <br></br> 
          <br></br> */
}
