import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import HomeCurry from "../../assets/images/Home/Home_Curry.png";

function Header() {
  return (
    <div className={styles.Header}>
      <div className="container">
        {/*<div className="item image">
          <img className="HomeCurryImg" src={HomeCurry} alt="Image of Curry" />
  </div>*/}
        <div className="item">
          <h1>
            Follow your <br></br>
            <span className="taste">Taste</span>
          </h1>
        </div>
        <div className="item">
          <Link to="/gerichte">
            <button className="light">Gerichte</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
