import React from "react";
import styles from "./Menu.module.css";
import Title from "../../components/title/Title";
import FullMenu from "../../components/full-menu/FullMenu";
import VeggieIcon from "../../assets/icons/Icon-Veggie.png";
import ChilliIcon1 from "../../assets/icons/Icon-Chilli-1.png";
import ChilliIcon2 from "../../assets/icons/Icon-Chilli-2.png";
import ChilliIcon3 from "../../assets/icons/Icon-Chilli-3.png";
import { useEffect } from "react";

function Menu() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Menu}>
      <Title
        title="Gerichte"
        text={
          <p>
            Hier eine Auswahl der thailändischen Spezialitäten, die wir gerne
            gemeinsam mit Ihnen kochen. Weitere Gerichte können natürlich
            individuell zusammengestellt werden. Die meisten Gerichte können auf
            Angfrage auch vegetarisch oder vegan zubereitet werden.
          </p>
        }
      />
      <div className="infos">
        <div className="item">
          <img className="icon" src={VeggieIcon} alt="Icon Vegetarian Symbol" />
          <p>Vegetarische Variante möglich</p>
        </div>
        <div className="item">
          <img
            className="icon"
            src={ChilliIcon1}
            alt="Icon Chilli mild Symbol"
          />
          <p>Mild</p>
        </div>
        <div className="item">
          <img
            className="icon"
            src={ChilliIcon2}
            alt="Icon Chilli hot Symbol"
          />
          <p>Mittel scharf</p>
        </div>
        <div className="item">
          <img
            className="icon"
            src={ChilliIcon3}
            alt="Icon Chilli very hot Symbol"
          />
          <p>Sehr scharf</p>
        </div>
      </div>

      <FullMenu />
    </div>
  );
}

export default Menu;
