import React from "react";
import styles from "./FullMenu.module.css";
import MenuList from "../../assets/database/menu.json";
import VeggieIcon from "../../assets/icons/Icon-Veggie.png";
import ChilliIcon1 from "../../assets/icons/Icon-Chilli-1.png";
import ChilliIcon2 from "../../assets/icons/Icon-Chilli-2.png";
import ChilliIcon3 from "../../assets/icons/Icon-Chilli-3.png";
import Vorspeisen from "../../assets/decor/Vorspeisen.png";
import Salate from "../../assets/decor/Salate.png";
import FleischFisch from "../../assets/decor/Fleisch-Fisch.png";
import Currys from "../../assets/decor/Currys.png";
import Nudelgerichte from "../../assets/decor/Nudelgerichte.png";
import Suppen from "../../assets/decor/Suppen.png";
import Reisgerichte from "../../assets/decor/Reisgerichte.png";
import Desserts from "../../assets/decor/Desserts.png";

function FullMenu() {
  return (
    <div className={styles.FullMenu}>
      <div className="container">
        <div className="row">
          <div className="column left order">
            <img
              className="SwooshLeft"
              src={Vorspeisen}
              alt="Menu Vorspeisen"
            />
            <ul className="content">
              {MenuList.vorspeisen.map((item) => {
                return (
                  <li className="item">
                    {item.vegetarian ? (
                      <img
                        className="veggie"
                        src={VeggieIcon}
                        alt="Icon Vegetarian Symbol"
                      />
                    ) : (
                      <div
                        style={{
                          width: `34px`,
                        }}
                      ></div>
                    )}

                    <div className="text">
                      <div className="firstRow">
                        <p className="name">{item.name}</p>
                        {item.hotness === 1 && (
                          <img
                            className="icon"
                            src={ChilliIcon1}
                            alt="Icon Chilli mild Symbol"
                          />
                        )}
                        {item.hotness === 2 && (
                          <img
                            className="icon"
                            src={ChilliIcon2}
                            alt="Icon Chilli hot Symbol"
                          />
                        )}
                        {item.hotness === 3 && (
                          <img
                            className="icon"
                            src={ChilliIcon3}
                            alt="Icon Chilli very hot Symbol"
                          />
                        )}
                      </div>

                      <p className="description">{item.description}</p>
                      <p className="description">{item.variant}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="column right order">
            <img className="SwooshRight" src={Salate} alt="Menu Salate" />
            <ul className="content">
              {MenuList.salate.map((item) => {
                return (
                  <li className="item">
                    {item.vegetarian ? (
                      <img
                        className="veggie"
                        src={VeggieIcon}
                        alt="Icon Vegetarian Symbol"
                      />
                    ) : (
                      <div
                        style={{
                          width: `34px`,
                        }}
                      ></div>
                    )}

                    <div className="text">
                      <div className="firstRow">
                        <p className="name">{item.name}</p>
                        {item.hotness === 1 && (
                          <img
                            className="icon"
                            src={ChilliIcon1}
                            alt="Icon Chilli mild Symbol"
                          />
                        )}
                        {item.hotness === 2 && (
                          <img
                            className="icon"
                            src={ChilliIcon2}
                            alt="Icon Chilli hot Symbol"
                          />
                        )}
                        {item.hotness === 3 && (
                          <img
                            className="icon"
                            src={ChilliIcon3}
                            alt="Icon Chilli very hot Symbol"
                          />
                        )}
                      </div>

                      <p className="description">{item.description}</p>
                      <p className="description">{item.variant}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="column left order">
            <img
              className="SwooshLeft"
              src={FleischFisch}
              alt="Menu Fleisch und Fisch"
            />
            <ul className="content">
              {MenuList.fleischfisch.map((item) => {
                return (
                  <li className="item">
                    {item.vegetarian ? (
                      <img
                        className="veggie"
                        src={VeggieIcon}
                        alt="Icon Vegetarian Symbol"
                      />
                    ) : (
                      <div
                        style={{
                          width: `34px`,
                        }}
                      ></div>
                    )}

                    <div className="text">
                      <div className="firstRow">
                        <p className="name">{item.name}</p>
                        {item.hotness === 1 && (
                          <img
                            className="icon"
                            src={ChilliIcon1}
                            alt="Icon Chilli mild Symbol"
                          />
                        )}
                        {item.hotness === 2 && (
                          <img
                            className="icon"
                            src={ChilliIcon2}
                            alt="Icon Chilli hot Symbol"
                          />
                        )}
                        {item.hotness === 3 && (
                          <img
                            className="icon"
                            src={ChilliIcon3}
                            alt="Icon Chilli very hot Symbol"
                          />
                        )}
                      </div>

                      <p className="description">{item.description}</p>
                      <p className="description">{item.variant}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="column right order">
            <img className="SwooshRight" src={Currys} alt="Menu Currys" />
            <ul className="content">
              {MenuList.currys.map((item) => {
                return (
                  <li className="item">
                    {item.vegetarian ? (
                      <img
                        className="veggie"
                        src={VeggieIcon}
                        alt="Icon Vegetarian Symbol"
                      />
                    ) : (
                      <div
                        style={{
                          width: `37px`,
                        }}
                      ></div>
                    )}

                    <div className="text">
                      <div className="firstRow">
                        <p className="name">{item.name}</p>
                        {item.hotness === 1 && (
                          <img
                            className="icon"
                            src={ChilliIcon1}
                            alt="Icon Chilli mild Symbol"
                          />
                        )}
                        {item.hotness === 2 && (
                          <img
                            className="icon"
                            src={ChilliIcon2}
                            alt="Icon Chilli hot Symbol"
                          />
                        )}
                        {item.hotness === 3 && (
                          <img
                            className="icon"
                            src={ChilliIcon3}
                            alt="Icon Chilli very hot Symbol"
                          />
                        )}
                      </div>

                      <p className="description">{item.description}</p>
                      <p className="description">{item.variant}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="column left order">
            <img
              className="SwooshLeft"
              src={Nudelgerichte}
              alt="Menu Nudelgerichte"
            />
            <ul className="content">
              {MenuList.nudelgerichte.map((item) => {
                return (
                  <li className="item">
                    {item.vegetarian ? (
                      <img
                        className="veggie"
                        src={VeggieIcon}
                        alt="Icon Vegetarian Symbol"
                      />
                    ) : (
                      <div
                        style={{
                          width: `37px`,
                        }}
                      ></div>
                    )}

                    <div className="text">
                      <div className="firstRow">
                        <p className="name">{item.name}</p>
                        {item.hotness === 1 && (
                          <img
                            className="icon"
                            src={ChilliIcon1}
                            alt="Icon Chilli mild Symbol"
                          />
                        )}
                        {item.hotness === 2 && (
                          <img
                            className="icon"
                            src={ChilliIcon2}
                            alt="Icon Chilli hot Symbol"
                          />
                        )}
                        {item.hotness === 3 && (
                          <img
                            className="icon"
                            src={ChilliIcon3}
                            alt="Icon Chilli very hot Symbol"
                          />
                        )}
                      </div>

                      <p className="description">{item.description}</p>
                      <p className="description">{item.variant}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="column right order">
            <img className="SwooshRight" src={Suppen} alt="Menu Suppen" />
            <ul className="content">
              {MenuList.suppen.map((item) => {
                return (
                  <li className="item">
                    {item.vegetarian ? (
                      <img
                        className="veggie"
                        src={VeggieIcon}
                        alt="Icon Vegetarian Symbol"
                      />
                    ) : (
                      <div
                        style={{
                          width: `37px`,
                        }}
                      ></div>
                    )}

                    <div className="text">
                      <div className="firstRow">
                        <p className="name">{item.name}</p>
                        {item.hotness === 1 && (
                          <img
                            className="icon"
                            src={ChilliIcon1}
                            alt="Icon Chilli mild Symbol"
                          />
                        )}
                        {item.hotness === 2 && (
                          <img
                            className="icon"
                            src={ChilliIcon2}
                            alt="Icon Chilli hot Symbol"
                          />
                        )}
                        {item.hotness === 3 && (
                          <img
                            className="icon"
                            src={ChilliIcon3}
                            alt="Icon Chilli very hot Symbol"
                          />
                        )}
                      </div>

                      <p className="description">{item.description}</p>
                      <p className="description">{item.variant}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="column left order">
            <img
              className="SwooshLeft"
              src={Reisgerichte}
              alt="Menu Reisgerichte"
            />
            <ul className="content">
              {MenuList.reisgerichte.map((item) => {
                return (
                  <li className="item">
                    {item.vegetarian ? (
                      <img
                        className="veggie"
                        src={VeggieIcon}
                        alt="Icon Vegetarian Symbol"
                      />
                    ) : (
                      <div
                        style={{
                          width: `37px`,
                        }}
                      ></div>
                    )}

                    <div className="text">
                      <div className="firstRow">
                        <p className="name">{item.name}</p>
                        {item.hotness === 1 && (
                          <img
                            className="icon"
                            src={ChilliIcon1}
                            alt="Icon Chilli mild Symbol"
                          />
                        )}
                        {item.hotness === 2 && (
                          <img
                            className="icon"
                            src={ChilliIcon2}
                            alt="Icon Chilli hot Symbol"
                          />
                        )}
                        {item.hotness === 3 && (
                          <img
                            className="icon"
                            src={ChilliIcon3}
                            alt="Icon Chilli very hot Symbol"
                          />
                        )}
                      </div>

                      <p className="description">{item.description}</p>
                      <p className="description">{item.variant}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="column right dessert order">
            <img className="SwooshRight" src={Desserts} alt="Menu Desserts" />
            <ul className="content">
              {MenuList.desserts.map((item) => {
                return (
                  <li className="item">
                    {item.vegetarian ? (
                      <img
                        className="veggie"
                        src={VeggieIcon}
                        alt="Icon Vegetarian Symbol"
                      />
                    ) : (
                      <div
                        style={{
                          width: `37px`,
                        }}
                      ></div>
                    )}

                    <div className="text">
                      <div className="firstRow">
                        <p className="name">{item.name}</p>
                        {item.hotness === 1 && (
                          <img
                            className="icon"
                            src={ChilliIcon1}
                            alt="Icon Chilli mild Symbol"
                          />
                        )}
                        {item.hotness === 2 && (
                          <img
                            className="icon"
                            src={ChilliIcon2}
                            alt="Icon Chilli hot Symbol"
                          />
                        )}
                        {item.hotness === 3 && (
                          <img
                            className="icon"
                            src={ChilliIcon3}
                            alt="Icon Chilli very hot Symbol"
                          />
                        )}
                      </div>

                      <p className="description">{item.description}</p>
                      <p className="description">{item.variant}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/*}
          
          
          
          
            */}

        {/*}<div className="column">
         
          
          
          
          
            
        </div>*/}
      </div>
    </div>
  );
}

export default FullMenu;
