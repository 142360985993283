import React from "react";
import styles from "./Home.module.css";
import Header from "../../components/header/Header";
import TwoColumnText from "../../components/two-column-text/Two-column-text";
import ServiceOverview from "../../components/service-overview/Service-overview";
import About from "../../components/about/About";
import background from "../../assets/images/Home/Background-temple.png";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.Home}>
      <Header />
      <TwoColumnText
        title="Unsere Kochkurse"
        c1="Viele von Ihnen sind bereits mit der thailändischen Küche in Kontakt
            gekommen - im Urlaub, im Restaurant oder mit Freunden. Haben Sie
            dabei auch über die Vielfalt und den Reichtum an Geschmäckern,
            Fragen und Aromen gestaunt? Möchten Sie mehr darüber erfahren, wie
            diese Köstlichkeiten zubereitet werden - in Verbindung mit ein paar
            schönen gemeinsamen Stunden mit Freunden, Familie, Kollegen oder
            neuen netten Bekanntschaften, die alle die Liebe zum Kochen und
            gemeinsamen Essen verbindet?"
        c2="Dann sind Sie bei unseren Kochkursen genau richtig! Wir organisieren
            für Sie und Ihre Kleingruppe oder Familie ein individuelles
            Kocherlebniss bei uns oder auch bei Ihnen zu Hause. Lassen Sie sich
            von den Geheimnisse der thailändischen Küche überraschen und
            genießen Sie mit Ihren Liebsten wertvolle Stunden."
        mode="dark"
      />
      <ServiceOverview />
      <TwoColumnText
        title="Original thailändische Küche"
        c1="Die thailändische Küche gilt zu Recht als eine der besten, kreativsten und vielfältigsten der Welt. Aus der Kombination frischer Gemüse und Kräuter (wie Zitronengras, Ingwer, Galgant, Erdnüssen, Kokos,…) und Fleisch oder Fisch, gepaart mit dem berühmten Jasminreis oder Nudeln, ergibt sich eine endlose Vielfalt an Aromen und Geschmäckern."
        c2="Und als Krönung natürlich die berühmten Chilis, die in allen Schärfegraden eingesetzt werden kann. Abgerundet wird das ganze durch Desserts aus Kokos, Bananen oder Ananas. Genießen Sie die Geschmäcke, Gerüche und Konsistenz dieser wunderbaren Küche – und fangen dabei vielleicht an vom nächsten Urlaub zu träumen…"
        background={background}
        mode="light"
      />
      <About />
    </div>
  );
}

export default Home;
