import React from "react";
import styles from "./Impressum.module.css";
import { useEffect } from "react";

function Impressum() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Impressum}>
      <div className="container">
        <h2>Impressum</h2>
        <p className="content">
          <b>
            "Follow Your Taste" <br></br>
          </b>
          Thomas Snay <br></br>
          Hildegard-von-Bingen-Str. 28 <br></br>
          64560 Riedstadt <br></br>
          snay(at)gmx.net <br></br>
          <b>
            Es wird gemäß §19 Abs. 1 Umsatzsteuergesetz keine Umsatzsteuer
            erhoben.
          </b>
        </p>
      </div>
    </div>
  );
}

export default Impressum;
