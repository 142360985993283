import React from "react";
import { Link } from "react-router-dom";
import styles from "./Service-overview.module.css";
import SwooshLeft from "../../assets/decor/Swoosh-left.png";
import SwooshRight from "../../assets/decor/Swoosh-right.png";

function ServiceOverview() {
  return (
    <div className={styles.ServiceOverview}>
      <div className="container">
        <div className="item left">
          <img className="SwooshLeft" src={SwooshLeft} alt="Decor Title left" />
          <div className="content">
            <p>
              Ob mit der Familie oder mit Freunden – unsere Kochkurse
              ermöglichen Euch eine wunderbare gemeinsame Zeit. In kleinen
              Gruppen von 4 bis 6 Personen bei uns in Erfelden. Ansonsten komme
              ich auch gerne zu Euch oder Euren Freunden nach Hause und wir
              kochen gemeinsam dort. Wenn Ihr noch nicht sicher seid mit wem und
              an welchem Termin Ihr den Kochkurs genießen könnt Ihr
              selbstverständlich auch einen Geschenkgutschein bestellen und den
              Termin später abstimmen – als schönes Geschenk zu Weihnachten,
              Geburtstag, Hochzeit oder anderen Anlässen
            </p>
            <Link to="/preise">
              <button className="dark">Preise</button>
            </Link>
          </div>
        </div>
        <div className="item right">
          <img
            className="SwooshRight"
            src={SwooshRight}
            alt="Decor Title right"
          />
          <div className="content">
            <p>
              Sie möchten den Zusammenhalt im Team stärken und sich in
              entspannter Atmosphäre besser privat kennenlernen? Einmal etwas
              anderes gemeinsam genießen statt immer Restaurants zu besuchen?
              Dann melden Sie sich – wir stellen Ihnen gerne einen individuellen
              Vorschlag zusammen. Wie bei den privaten Kochkursen bei uns in
              Erfelden für 4 bis 6 Personen. Für größere Gruppen komme ich gerne
              auch in eine von Ihnen vorgeschlagene Küche (ggf. können wir auch
              eine Örtlichkeit für mehr Personen organisieren, was jedoch
              abhängig von Verfügbarkeit ist – deshalb wäre in dem Fall eine
              gewisse terminliche Flexibilität vorteilhaft).
            </p>
            <Link to="/preise">
              <button className="dark">Preise</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceOverview;
