import React from "react";
import styles from "./Contact.module.css";
import Pia from "../../assets/images/Home/Pia.png";
import ContactForm from "../../components/contact-formular/ContactForm";
import { useEffect } from "react";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Contact}>
      <div className="container">
        <div className="section">
          <div className="item text">
            <h2>Kontakt</h2>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Kontaktieren Sie uns – Wir sind für Sie da!
              </span>
              <br />
              <br />
              Haben Sie Fragen, Anregungen oder möchten Sie mehr über unsere
              thailändischen Kochkurse erfahren? Wir freuen uns darauf, von
              Ihnen zu hören! Ihre Meinung und Ihr Interesse sind uns wichtig.
              Zögern Sie nicht, uns zu kontaktieren, und wir stehen Ihnen gerne
              zur Verfügung, um Ihre Anliegen zu besprechen und Ihnen bei der
              Planung Ihres kulinarischen Abenteuers zu helfen.
            </p>
            <p className="info">
              <b>Saraphee Snay</b> <br></br>
              sarapeey(at)gmx.de <br></br>
              +49 175 1057359 (Tel. oder Whatsapp) <br></br>
              64560 Riedstadt / Erfelden <br></br>
              Hildegard-von-Bingen-Str. 28
            </p>
          </div>
          <div className="item image">
            <img className="pia" src={Pia} alt="Image of Pia" />
          </div>
        </div>
        <div className="section form">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;
