import React from "react";
import styles from "./Gallery.module.css";
import Title from "../../components/title/Title";
import Pictures from "../../components/pic-gallery/Pictures";
import { useEffect } from "react";

function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Gallery} id="main">
      <Title title="Galerie" />
      <Pictures />
    </div>
  );
}

export default Gallery;
