import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Menu from "./pages/menu/Menu";
import Prices from "./pages/prices/Prices";
import Gallery from "./pages/gallery/Gallery";
import Contact from "./pages/contact/Contact";
import Impressum from "./pages/impressum/Impressum";
import Datenschutz from "./pages/datenschutz/Datenschutz";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/gerichte" exact element={<Menu />}></Route>
          <Route path="/preise" exact element={<Prices />}></Route>
          <Route path="/galerie" exact element={<Gallery />}></Route>
          <Route path="/kontakt" exact element={<Contact />}></Route>
          <Route path="/impressum" exact element={<Impressum />}></Route>
          <Route path="/datenschutz" exact element={<Datenschutz />}></Route>
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
