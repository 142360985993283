import React from "react";
import styles from "./Category.module.css";
import { Link } from "react-router-dom";

function Category({
  image,
  title,
  description,
  underheadline,
  prices,
  notice,
  direction,
}) {
  return (
    <div className={styles.Category}>
      <div className={`container ${direction}`}>
        <div className="item image">
          <img className="img" src={image} alt="Food Image" />
        </div>
        <div className="item content">
          <h2>{title}</h2>
          <p>{description}</p>
          <p className="underheadline">{underheadline}</p>
          <div className="pricelist split">
            {prices.map((item) => {
              return (
                <p>
                  {item.item}
                  <span>{item.costs}</span>
                </p>
              );
            })}
          </div>
          {title === "Unternehmen & Vereine" && (
            <div class="contact">
              <Link to="/kontakt">
                <button className="dark">Kontakt</button>
              </Link>
            </div>
          )}
          {title === "Gutscheine" && (
            <div class="voucher">
              <a
                href="FollowYourTasteGutschein(DE).pdf"
                download="FollowYourTasteGutschein(DE).pdf"
              >
                <button className="dark">Download (DE)</button>
              </a>
              <a
                href="FollowYourTasteGutschein(EN).pdf"
                download="FollowYourTasteGutschein(EN).pdf"
              >
                <button className="dark">Download (EN)</button>
              </a>
            </div>
          )}
          <p className="notice">{notice}</p>
        </div>
      </div>
    </div>
  );
}

export default Category;

/* */
