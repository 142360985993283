import React from "react";
import styles from "./About.module.css";
import Pia from "../../assets/images/Home/Pia.png";

function About() {
  return (
    <div className={styles.About}>
      <div className="container">
        <div className="item text">
          <h2>Saraphee Snay</h2>
          <p>
            Mein Name ist Saraphee Snay, genannt „Pia“. Ich bin im schönen Süden
            Thailands in Nakhorn Si Thammarat, nahe der Ferieninsel Phuket,
            aufgewachsen. Nach meinem Studium und Arbeit als Informatikerin in
            Bangkok bin ich 2001 nach Europa gezogen. Nach Stationen mit meiner
            Familie in der Schweiz, Indonesien und Südkorea sind wir jetzt seit
            2017 in Riedstadt Erfelden zu Hause. Nachdem wir oft mit Freunden
            viel Spaß beim gemeinsamen Kochen hatten habe ich beschlossen meine
            Freude am Kochen und meine Begeisterung für die Küche meines
            Heimatlandes mit anderen teilen zu wollen und 2018 angefangen,
            Kochkurse anzubieten. Dazu bin ich als Dozentin für thailändisches
            Kochen an der Kreisvolkshochschule Groß-Gerau tätig.
          </p>
        </div>
        <div className="item image">
          <img className="pia" src={Pia} alt="Image of Pia" />
        </div>
      </div>
    </div>
  );
}

export default About;
