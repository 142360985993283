import React from "react";
import styles from "./Prices.module.css";
import PriceList from "../../assets/database/prices.json";
import Title from "../../components/title/Title";
import Category from "../../components/price-category/Category";
import servicePrivate from "../../assets/images/Prices/service-privat.png";
import serviceFamily from "../../assets/images/Prices/service-family.png";
import servicePaar from "../../assets/images/Prices/service-paar.png";
import serviceCompany from "../../assets/images/Prices/service-company.png";
import serviceVoucher from "../../assets/images/Prices/service-voucher.png";
import { useEffect } from "react";

function Prices() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Prices}>
      <Title
        title="Service &#38; Preise"
        text={
          <p>
            Tauchen Sie ein in die Aromen und Techniken der authentischen
            thailändischen Küche, Egal, ob Sie eine Freundesgruppe mit
            kulinarischen Vorlieben sind, eine Familie, die nach einer
            unterhaltsamen Aktivität sucht, oder ein Unternehmen, das ein
            einzigartiges Teambuilding-Erlebnis wünscht – wir haben das perfekte
            Paket für Sie. <br /> Unsere vielfältigen Angebote sind darauf
            ausgerichtet, Ihnen ein unvergessliches Erlebnis zu bieten, bei dem
            Sie nicht nur die Kunst des thailändischen Kochens erlernen, sondern
            auch wertvolle Erinnerungen schaffen können. Wählen Sie das perfekte
            Paket aus und genießen Sie eine schöne gemeinsame Zeit mit ihren
            Freunden, der Familie oder Kollegen. <br />
            <br /> Für weitere Informationen und zur Buchung Ihres
            thailändischen Kochkurs-Erlebnisses zögern Sie bitte nicht, uns zu
            kontaktieren. Wir freuen uns darauf, mit Ihnen diese geschmackvolle
            Reise anzutreten!
          </p>
        }
      />
      <div className="categories">
        <Category
          image={servicePrivate}
          title="Privat"
          description="Genießen Sie eine private kulinarische Reise mit Ihren Freunden. Unser Privatpaket bietet eine intime Atmosphäre, die persönliche Aufmerksamkeit und ein praktisches Erlebnis für jeden Teilnehmer gewährleistet. Schaffen Sie gemeinsam bleibende Erinnerungen."
          underheadline="Preise pro Teilnehmer (4 - 6 Teilnehmer):"
          prices={PriceList.privat}
          notice="* Alle Preise inklusive Zutatenbeitrag (17,00€ - 20,00€)"
          direction="left"
        />
        <Category
          image={serviceFamily}
          title="Familie"
          description="Unser Familienpaket wurde entwickelt, um alle Fähigkeitsstufen und Altersgruppen zu berücksichtigen und bietet ein wunderbares Gemeinschaftserlebnis für Groß und Klein. Teilen Sie die Freude am Kochen mit der ganzen Familie und erleben Sie ein Abenteuer mit ihren Liebsten."
          underheadline="Preise pro Familie (2 Erwachsene + 2 Kinder bis 11 Jahre):"
          prices={PriceList.family}
          notice="* Alle Preise inklusive Zutatenbeitrag (17,00€ - 20,00€)"
          direction="right"
        />
        <Category
          image={servicePaar}
          title="Paare"
          description="Unser Paar-Paket bietet Ihnen die Möglichkeit, Seite an Seite mit Ihrem Partner oder Ihrer Partnerin eine kulinarische Reise zu unternehmen. 
          Verbringen Sie qualitätsvolle Zeit in der Küche und zaubern Sie gemeinsam köstliche Gerichte, die nicht nur Ihren Gaumen erfreuen, sondern auch Ihre Verbindung stärken. Dieses Paket ist die perfekte Gelegenheit, um Ihre Beziehung mit einem Hauch von Exotik zu würzen.
          "
          underheadline="Preise pro Paar (2 Erwachsene):"
          prices={PriceList.paare}
          notice="* Alle Preise inklusive Zutatenbeitrag (17,00€ - 20,00€)"
          direction="left"
        />
        <Category
          image={serviceCompany}
          title="Unternehmen &#38; Vereine"
          description="Möchten Sie Ihre Firmenveranstaltungen aufpeppen? Unser Unternehmenspaket bietet eine einzigartige Gelegenheit zum Teambuilding, die sowohl lehrreich als auch unterhaltsam ist. Steigern Sie die Zusammenarbeit, Kommunikation und Kreativität Ihrer Teammitglieder, während sie die Kunst des thailändischen Kochens meistern."
          underheadline="Kontaktieren Sie uns und wir bieten Ihnen gerne ein 
          individuelles Paket angepasst an Ihre Bedürfnisse an."
          prices={[]}
          notice=""
          direction="right"
        />
        <Category
          image={serviceVoucher}
          title="Gutscheine"
          description="Machen Sie Ihren Liebsten eine schöne Freude zum Geburtstag, zu Weihnachten oder anderen besonderen Anlässen und verschenken Sie einen Gutschein für einen gemeinsamen Kochkurs."
          underheadline="Gutschein zum personalisieren und ausdrucken (Download):"
          prices={[]}
          notice="* Zahlung erfolgt bei Einlösen des Gutscheines. Alternativ kann der Gutschein auch persönlich abgeholt werden und vor Ort bezahlt werden."
          direction="left"
        />
      </div>
    </div>
  );
}

export default Prices;
// {PriceList.privat.map((item) => {
//   return ();})}
